<template>
    <div class="panelMainWrapper relative">
        <div ref="panelMainWrapper" class="bg-white rounded-lg mb-5 panelWrapper relative" :style="{borderLeft: `10px solid ${ item.color ? item.color : '#2BAD96' }`}">
            <span class="line absolute"></span>
            <div class="py-6 flex items-center relative" :style="{paddingRight: '26px', height: getHeight(item)}">
                <div 
                    class="border-r border-secondary-four flex items-center justify-end flex-shrink-0" 
                    :style="{width: widthSet[item.i_am], height:'70px'}"
                >
                    <span class="text-primary-one fw-800 block text-right" :style="{fontSize: fontSizeSet[item.i_am], paddingRight: '20%'}">
                        {{ item.index_code }}
                    </span>
                </div>
                <div class="pl-5 pr-2 border-r border-secondary-four flex-shrink-0 grid" style="width: 404px;gap: 10px;">
                    <p 
                        class="fw-500 fs-12" 
                        :class="(item.i_am == 'goal' || item.i_am == 'objective') && ''"
                        :style="{color: item.progress_time_color}"
                    >
                        {{ item.progress_headline }}
                    </p>
                    <input 
                        v-model="item.title"
                        @blur="handleUpdate(item)"
                        class="fw-800 fs-16 text-primary-one bg-transparent w-full"
                        :style="getFontSize(item)"
                    >
                    <p 
                        v-if="item.i_am == 'goal' || item.i_am == 'objective'"
                        class="fw-500 fs-10 flex gap-1 items-center"
                    >
                        <component class="w-3 h-3 flex-shrink-0" :is="badgeIcon[item.progress_achieve_icon]" />
                        {{ item.progress_goal_headline }}
                    </p>
                </div>

                <DateTime v-if="item.i_am != 'goal'" :item="item" />

                <div v-if="item.i_am != 'goal'"  style="width: 580px;" class="flex-shrink-0 mt-6">
                    <RangeProgress
                        width="100%"
                        height="6px"
                    >
                        <RangeBar
                            :class="item.weight_progress_towards_target < item.weight_target ? 'z-10' : ''" 
                            bg="#2B80AD"
                            :text="`Actual: ${Math.round(item.weight_progress_towards_target)}%`"
                            :value="getPositionInProgress(item.weight_progress_towards_target, item.weight_target)" 
                        />
                        <RangeBar
                            :class="item.weight_progress_towards_target > item.weight_target ? 'z-10' : ''" 
                            bg="#ABA62B" 
                            :text="`Target: ${Math.round(item.weight_target)}%`"
                            :value="getPositionInProgress(item.weight_target, item.weight_progress_towards_target)" 
                        />
                    </RangeProgress>
                    <div class="border-l w-full my-1 border-secondary-one fs-10 fw-500 flex items-center text-center overflow-hidden">
                        <span
                            v-for="quarter in listQuarters(new Date(item.start_date), new Date(item.end_date))"
                            :key="quarter"
                            class="w-full block border-r border-secondary-one  whitespace-nowrap truncate"
                        >
                            {{quarter}}
                        </span>
                        
                    </div>
                    <RangeProgress
                        width="100%" 
                        height="4px"
                    >
                        <RangeBar 
                            :class="getProgress(item) > item.weight_progress_towards_target ? 'z-10' : ''" 
                            :bg="(item.weight_progress_towards_target > getProgress(item)) ? '#2BAD7E' : '#2B80AD'" 
                            tooltipPosition="bottom"
                            :hasTooltip="false"
                            :value="item.weight_progress_towards_target"
                            :goal="item.goal"
                        />
                        <RangeBar 
                            :class="getProgress(item) < item.weight_progress_towards_target ? 'z-10' : ''" 
                            :bg="(item.weight_progress_towards_target < getProgress(item)) ? '#AD2B81' : '#2B80AD'"
                            tooltipPosition="bottom"
                            :value="getProgress(item)" 
                            :goal="item.goal"
                        />
                    </RangeProgress>
                </div>

                <PanelHeaderForGoal v-else :item="item" />

                <div class="flex justify-between items-center flex-shrink-0" style="margin-left: 30px; width: 130px;">
                    <Avatar 
                        :item="item"
                        :goalId="goalId"
                    />
                    <div class="grid items-end align-middle text-right">
                        <div class="flex items-center gap-2 justify-end">
                            <button @click="handleDelete(item, parent)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 3.5h-11m4 3v4m3-4v4m-4-9h5m2 2V13a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V3.5" stroke="#C9C9C9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </button>
                            <Flag
                                :style="{color: get(priorities[item.priority], 'color') || '#ad2b81'}" 
                                class="w-4 h-4 cursor-pointer"
                            />
                        </div>
                        <h3 class="fw-500 fs-10 text-secondary-two mt-1">WEIGHT: {{ item.weight }}%</h3>
                        <SwitchComponent
                            :value="item.status == 1 ? true : false"
                            @onClick="handleStatus"
                            style="transform: scale(.6); margin-right: -10px;" 
                            class="transform  opacity-50 ml-auto"
                        />
                    </div>
                </div>
                <PanelAction
                    :handleCreate="handleCreate"
                    :handleDelete="handleDelete"
                    :item="item"
                    :parent="parent"
                    :handleExpandedId="handleExpandedId"
                    :handleInfoId="handleInfoId"
                />
            </div>
            <Transition name='animate' @after-leave="handleLineTree">
                <PanelDetail
                    v-if="infoIds.includes(`${item.id}-${item.i_am}`)"
                    :width="widthSet[item.i_am]"
                    :handleCreate="handleCreate"
                    :handleDelete="handleDelete"
                    :handleUpdate="handleUpdate"
                    :item="item"
                    :goalId="goalId"
                />
            </Transition>
        </div>

        <Transition name='animate' @after-leave="handleLineTree">
            <div 
                v-if="showAble(item)" 
                :style="getOffsetLeft(item)"
            >
                <template v-for="child in item.children">
                    <Panel
                        v-if="getChild(child)"
                        :key="child.id"
                        :item="child"
                        :index="index"
                        :parent="item"
                        :handleCreate="handleCreate"
                        :handleDelete="handleDelete"
                        :handleUpdate="handleUpdate"
                        :handleExpandedId="handleExpandedId"
                        :handleInfoId="handleInfoId"
                        :goalId="goalId"
                    />
                </template>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onUpdated, watch, set } from 'vue'
import RangeProgress from '@/components/dashboard/goal/components/RangeProgress/RangeProgress.vue'
import RangeBar from '@/components/dashboard/goal/components/RangeProgress/RangeBar.vue'
import PanelDetail from '@/components/dashboard/goal/PanelDetail.vue'
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import Avatar from './components/Avatar.vue'
import PanelAction from './components/PanelAction.vue'
import Panel from './Panel.vue'
import DateTime from './components/DateTime.vue'
import { useGoal } from '@/views/screens/dashboard/useGoal.js'

import TrophyIcon from '@/components/dashboard/goal/icons/badge/Trophy.vue'
import CrownIcon from '@/components/dashboard/goal/icons/badge/Crown.vue'
import MedalIcon from '@/components/dashboard/goal/icons/badge/Medal.vue'
import ThumbsUpIcon from '@/components/dashboard/goal/icons/badge/ThumbsUp.vue'
import { get } from 'lodash'
import usePanel from '@/components/dashboard/goal/usePanel.js'
import PanelHeaderForGoal from './PanelHeaderForGoal.vue'

const { 
    showActiveOnly,
    getProgress, 
    handleExpandedId, 
    expandedIds, 
    infoIds, 
    handleInfoId, 
    priorities, 
} = useGoal()
const { getPositionInProgress } = usePanel()
const badgeIcon = {
    Trophy: TrophyIcon,
    Crown: CrownIcon,
    Medal: MedalIcon,
    ThumbsUp: ThumbsUpIcon
}

const props = defineProps({
    item: Object,
    index: Number,
    handleCreate: Function,
    handleDelete: Function,
    handleUpdate: Function,
    parent: Object,
    goalId: Number,
});

const widthSet = {
    'goal': '98px',
    'objective': '78px',
    'key-result': '62px',
    'key-responsibility': '54px',
    'key-activity': '54px',
    'activity': '54px',
}
const fontSizeSet = {
    'goal': '20px',
    'objective': '16px',
    'key-responsibility': '16px',
    'key-result': '16px',
    'key-activity': '14px',
    'activity': '14px',
}

const panelMainWrapper = ref()

const handleLineTree = () => 
{
    let parent   = panelMainWrapper.value
    let line     = (parent) ? parent.querySelector('.line') : null
    let children = panelMainWrapper.value?.nextElementSibling
    let nextSiblingIsVisible = (parent && parent.nextElementSibling) ? parent.nextElementSibling.style.display != 'none' : true
    
    if(children && nextSiblingIsVisible && children.lastElementChild && children.lastElementChild.firstElementChild){
        children = children.lastElementChild.firstElementChild

        let parentInfo = parent.getBoundingClientRect()
        let childInfo  = children.getBoundingClientRect()
        let totalDistance = (childInfo.top - parentInfo.top)
        let calculatedDistance = (totalDistance - (parentInfo.height/2) + (childInfo.height/2))
        
        if(!line) return
        line.style.cssText = `
            top: ${parentInfo.height/2}px;
            height: ${calculatedDistance}px;
            display: block;
        `
        line.parentElement.classList.remove('no')
    }
    else{
        if(line){
            line.style.cssText = `
                display: none;
            `
            line.parentElement.classList.add('no')
        }
    }
}

const getHeight = (item) => {
    if(item.i_am == "goal") return '140px'
    if(item.i_am == "objective") return '130px'
    if(item.i_am == "key-result") return '120px'
    if(item.i_am == "key-activity") return '108px'
}

const getOffsetLeft = (item) => {
    if(item.i_am == "goal"){
        return {
            marginLeft: '20px',
            width: `calc(100% - 20px)`,
            '--offsetLeft': '20px'
        }
    }
    if(item.i_am == "objective"){
        return {
            marginLeft: '16px',
            width: `calc(100% - 16px)`,
            '--offsetLeft': '16px'
        }
    }
    if(item.i_am == "key-result"){
        return {
            marginLeft: '8px',
            width: `calc(100% - 8px)`,
            '--offsetLeft': '8px'
        }
    }
    if(item.i_am == "key-activity"){
        return {
            marginLeft: '8px',
            width: `calc(100% - 8px)`,
            '--offsetLeft': '8px'
        }
    }
}

const getFontSize = (item) => {
    if(item.i_am == "goal") return {
        fontSize: '16px',
        fontWeight: '800',
    }
    if(item.i_am == "objective") return {
        fontSize: '16px',
        fontWeight: '700',
    }
    if(item.i_am == "key-result") return {
        fontSize: '16px',
        fontWeight: '600',
    }
    if(item.i_am == "key-activity") return {
        fontSize: '14px',
        fontWeight: '700',
    }
}

function getQuarterWithYear(date) {
  return 'Q' + Math.ceil((date.getMonth()+ 1)/3) + '-' + date.getFullYear();
}

function listQuarters(sDate, eDate) {
  // Ensure start is the earlier date;
  if (sDate > eDate) {
    var t = eDate;
    eDate = sDate;
    sDate = t;
  }

  // Copy input start date do don't affect original
  sDate = new Date(sDate);
  
  // Set to 2nd of month so adding months doesn't roll over
  // and not affected by daylight saving
  sDate.setDate(2);

  // Initialise result array with start quarter
  var startQ = getQuarterWithYear(sDate);
  var endQ   = getQuarterWithYear(eDate);
  var result = [startQ];
  
  // List quarters from start to end
  while (startQ != endQ) {
    sDate.setMonth(sDate.getMonth() + 3);
    startQ = getQuarterWithYear(sDate);
    result.push(startQ);
  } 
  
  return result;
}

const handleStatus = (val) => {
    props.item.status = val == false ? 0 : 1
    props.handleUpdate(props.item)
}

const actual_progress = ref(0)

const getChild = (child) => {
    if(!showActiveOnly.value){
        return child
    }

    if(child.status){
        return child
    }
}

const showAble = (item) => {
    return item.i_am !== 'key-activity' && expandedIds.value.includes(`${item.id}-${item.i_am}`) && item.children
}

onMounted(() => {
    handleLineTree()
    window.addEventListener('click', handleLineTree)
    window.addEventListener('mousemove', handleLineTree)
    if(props.item.i_am == 'goal') handleInfoId(props.item)
})

onUpdated(() => {
    handleLineTree()
})

onUnmounted(() => {
    handleLineTree()
    window.removeEventListener('click', handleLineTree)
    window.removeEventListener('mousemove', handleLineTree)
})
</script>

<style scoped>
    .drop-shadow{
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
    .panelWrapper::before{
        content: '';
        position: absolute;
        top: 50%;
        right: calc(100% + 10px);
        height: 2px;
        width: calc(var(--offsetLeft) + 19px);
        background-color: #C9C9C9;
    }
    .levelOne>.panelWrapper::before{
        width: 19px;
    }
    .levelOne > .no.panelWrapper::before{
        display: none;
    }
    .line{
        display: block;
        left: -29px;
        height: 100%;
        width: 2px;
        background-color: #C9C9C9;
        display: none;
    }

    
    .animate-leave-from,
    .animate-enter-to{
        opacity: 1;
        transform: translateX(0px);
    }
    .animate-enter,
    .animate-leave-to{
        opacity: 0;
        transform: translateX(60px);
    }
    .animate-leave-active{
        transition: all 0.3s ease;
    }
    .animate-enter-active{
        transition: all 0.6s ease;
    }
</style>