<template>
    <div class="py-3 pt-1">
        <div class="flex border-b">
            <div 
                class=" flex gap-6 fw-500 fs-12 text-secondary-two whitespace-nowrap" 
                :style="{paddingLeft: width, marginBottom: '-1px', width: '410px'}"
            >
                <button 
                    @click="activePrimaryComponent = 'BasicInformation'" 
                    class="py-2"
                    :class="activePrimaryComponent == 'BasicInformation' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                >
                    Basic Information
                </button>
                <button 
                    @click="activePrimaryComponent = 'Progress'" 
                    v-if="item.i_am == 'key-activity' "
                    class="py-2"
                    :class="activePrimaryComponent == 'Progress' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                >
                    Progress
                </button>
                <button 
                    v-if="item.i_am=='key-activity'"
                    @click="activePrimaryComponent = 'Outcome'" 
                    class="py-2"
                    :class="activePrimaryComponent == 'Outcome' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                >
                    Outcome
                </button>
                <button 
                    @click="activePrimaryComponent = 'ActivityLog'" 
                    v-if="item.i_am == 'key-activity' "
                    class="py-2"
                    :class="activePrimaryComponent == 'ActivityLog' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                >
                    Activity Log
                </button>
            </div>
            
            <div class="flex gap-6 fw-500 fs-12 text-secondary-two">
                <template v-if="activePrimaryComponent == 'BasicInformation'">
                    <button 
                        @click="activeBasicInfoComponent = 'BasicInfoProgress'" 
                        class="py-2 ml-20"
                        :class="activeBasicInfoComponent == 'BasicInfoProgress' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                    >
                        Progress
                    </button>
                    <button 
                        @click="activeBasicInfoComponent = 'Confidence'" 
                        class="py-2"
                        :class="activeBasicInfoComponent == 'Confidence' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                    >
                        Confidence Review
                    </button>
                    <button 
                        @click="activeBasicInfoComponent = 'Outcomes'" 
                        class="py-2"
                        :class="activeBasicInfoComponent == 'Outcomes' ? 'text-primary-one border-b-2 border-primary-one' : ''"
                    >
                        Outcomes
                    </button>
                </template>
            </div>
        </div>
        <div>
            <component 
                :width="width"
                :is="primaryComponents[activePrimaryComponent]"
                :activeComponent="activeBasicInfoComponent"
                :parent="item"
                :handleCreate="handleCreate"
                :handleDelete="handleDelete"
                :handleUpdate="handleUpdate"
                :goalId="goalId"
             />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useGoal } from '@/views/screens/dashboard/useGoal.js'

defineProps({
    width: {
        type: String,
        default: '98px'
    },
    handleCreate: Function,
    handleDelete: Function,
    handleUpdate: Function,
    item: Object,
    goalId: Number
});

const activeBasicInfoComponent = ref('BasicInfoProgress')
const { 
    activePrimaryComponent, 
    // activeBasicInfoComponent, 
    primaryComponents 
} = useGoal()
</script>