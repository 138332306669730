<template>
    <div>
        <div
            @dblclick="makeIsolate(item)" 
            class="fs-14 fw-600 mb-8 flex gap-1 justify-between items-center rounded shadow text-primary-three p-4 w-full"
            :style="{backgroundColor: item.color}"
        >
            <span class="truncate">{{ item.title }}</span>
            <button @click="handleExpandedId(item)">
                <svg 
                v-if="item.i_am != 'key-activity'"
                class="transform"
                :class="showAble(item) ? 'rotate-180' : 'rotate-0'"
                width="13" 
                height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.00953 -0.000197459L-0.000470991 6.0098L1.41253 7.4248L6.00953 2.8248L10.6055 7.4248L12.0195 6.0108L6.00953 -0.000197459Z" fill="white"/>
                </svg>                
            </button>
        </div>

        <template v-if="showAble(item)">
            <div v-for="child in item.children" :key="`${child.i_am}-${child.id}`">
                <Item
                    v-if="child.i_am !== 'activity' && shouldShow(child)"
                    :handleExpandedId="handleExpandedId"
                    :item="child"
                    :goalId="goalId"
                />
            </div>
        </template>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { cloneDeep, isEmpty } from 'lodash'
    import { useGoal } from '@/views/screens/dashboard/useGoal.js'
    import Item from './Item.vue'
    
    const props = defineProps({
        item: Object,
        goalId: [Number, String],
        handleExpandedId: Function
    })
    const { goals, showActiveOnly, expandedIds } = useGoal()
    const localGoalOriginalData = cloneDeep(goals.value)
    const makeIsolate = (item) => {
        item.isIsolated = !item?.isIsolated
        if(!item.isIsolated) {
            goals.value = localGoalOriginalData
            return
        }

        const result = filterData(cloneDeep(goals.value), (i) => {
            return (i.id == item.id && i.i_am == item.i_am) ^ i?.hasChild
        })
        goals.value = result
    }

    const filterData = (items, cb) => {
        const again = (i, cb) => {
            if(i.children && i.children.length) {
                let children = i.children.filter(j => again(j, cb))
                i.children = children
                if(!isEmpty(children)){
                    i.hasChild = true
                }
            }

            i.hasChild = cb(i)
            return i.hasChild;
        }
        return items.filter(i => again(i, cb));
    }

    const shouldShow = (item) => {
        if(!showActiveOnly.value) return item
        if(item.status) return item
    }
    const showAble = (item) => {
        return item.i_am !== 'key-activity' && expandedIds.value.includes(`${item.id}-${item.i_am}`) && item.children
    }
</script>

<style scoped>
.shadow{
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}
</style>