export default function () {
  const getPositionInProgress = (value1, value2) => {
    let v1 = value1 < 0 ? value1 : 0;
    let v2 = value2 < 0 ? value2 : 0;
    let min = Math.min(v1, v2);

    v1 = value1 > 100 ? value1 : 100;
    v2 = value2 > 100 ? value2 : 100;

    let max = Math.max(v1, v2);

    let position = value1;

    if (max > 100 || min < 0) {
      let total = Math.abs(min) + max;

      if (value1 >= 0 && value1 <= 100 && value2 > 100) {
        position = (value1 / total) * 100;
      }

      if (value1 < 0 && value2 >= 0 && value2 <= 100) {
        position = (0 / total) * 100;
      }

      if (value1 < 0 && value2 < 0) {
        position = (value1 < value2 ? 0 : value1 / total) * 100;
      }

      if (value1 >= 0 && value1 <= 100 && value2 < 0) {
        position = (value1 / total) * 100;
      }

      if (value1 > 100 && value2 >= 0 && value2 <= 100) {
        position = (value1 / total) * 100;
      }

      if (value1 > 100 && value2 < 0) {
        position = (total / total) * 100;
      }

      if (value1 > 100 && value2 > 100) {
        position = (value1 / total) * 100;
      }
    }

    return Math.abs(position);
  }

  return {
    getPositionInProgress,
  }
}
