<template>
    <div 
        class="flex justify-end gap-2 text-secondary-two fw-600 fs-12 absolute right-0 top-full whitespace-nowrap" 
        style="right: 18px; transform: translateY(-24px);"
    >
        <button 
            @click="handleCreate(item, parent)"
        >
            + Add {{ title }}
        </button>
        <div class="flex gap-2 text-secondary-five">
            <button 
                @click="() => {
                    if(item.i_am == 'key-activity'){
                        childIconActive = handleInfoId(item)
                        infoIconActive = false
                    }else{
                        childIconActive = handleExpandedId(item)
                        infoIconActive = false
                    }
                }"
                class="flex gap-x-2"
            >
                <svg :class="item.childVisibility && 'transform rotate-180'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10"/><path d="M10.75 7 8 9.75 5.25 7" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </button>
            <button 
                @click="() => {
                    infoIconActive = handleInfoId(item)
                    childIconActive = false

                }"
                class="flex gap-2"
                :class="infoIconActive ? 'text-primary-four' : ''"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 7.5H8V11h.5" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.875 5.875a.625.625 0 1 0 0-1.25.625.625 0 0 0 0 1.25Z" fill="currentColor"/></svg>
            </button>
        </div>
    </div>
</template>

<script setup>
    import { set, ref, computed } from 'vue'

    const props = defineProps({
        item: Object,
        parent: Object,
        handleCreate: Function,
        handleDelete: Function,
        handleExpandedId: Function,
        handleInfoId: Function,
    });

    const infoIconActive = ref(false)
    const childIconActive = ref(true)

    const titleSet = {
        'goal': 'Goal',
        'objective': 'Objective',
        'key-result': 'Key Result',
        'key-activity': 'Key Activity',
        'activity': 'Activity'
    }
    const childTitleSet = {
        'goal': 'Objective',
        'objective': 'Key Result',
        'key-result': 'Key Activity',
        'key-activity': 'Activity',
        'activity': 'Activity'
    }
    const title = computed(() => titleSet[props.item.i_am] || '');
    const childTitle = computed(() => childTitleSet[props.item.i_am] || '');
    const toggleDetailVisibility = (item) => {
        set(item, 'detailVisibility', !item.detailVisibility)
    }
    
    const toggleChildVisibility = (item) => {
        set(item, 'childVisibility', !item.childVisibility)
    }

</script>