<template>
    <div>
        <img
            class="w-9 h-9 block cursor-pointer border-2 rounded-full object-cover object-center drop-shadow"
            :src="source.src"
            :title="source.name" 
            @click="showModal = true"
        />
        <Modal v-model="showModal">
            <UserList 
                :item="item" 
                :goalId="goalId" 
                :updateActivity="updateActivity"
                :updateGoalObjectForSpecificId="updateGoalObjectForSpecificId"
                @close="showModal = false"
            />
        </Modal>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { fullName } from '@/plugins/lodash.js'
    import Modal from '@/elements/atom/Modal.vue'
    import UserList from '@/components/dashboard/goal/components/UserList.vue'
    import { useGoal } from '@/views/screens/dashboard/useGoal.js'

    const props = defineProps({
        item: Object,
        goalId:[Number, String]
    })
    const showModal =  ref(false)
    const { 
        updateActivity,
        updateGoalObjectForSpecificId 
    } = useGoal()
    const source = computed(() => {
        let owner = props.item.owner || null
        if(owner) {
            return {
                name: fullName(owner),
                src: owner.image?.file_path || '/images/profile.png'
            }
        }
        return '/images/profile.png'
    });

</script>