<template>
    <BaseLayout>
        <template v-slot:workspace>
            <Header/>
        </template>
        <template v-if="!showConfiguration" v-slot:leftSide>
            <LeftSidebar />
        </template>
        <button
            v-if="!goals || !goals.length"
            class="text-primary-four fw-600 fs-16 ml-10 mt-5"
            @click="handleCreate({i_am: 'goal'})"
        >+ Add Goal
        </button>
        <div 
            v-if="goals.length"
            :class="{'opacity-60 pointer-events-none': loader}" 
            class="pl-8 pr-16 mt-6 overflow-y-auto h-960px relative"
        >
            <div v-for="(item, index) in goals" :key="item.id" :index="index">
                <Panel
                    v-if="!isEmpty(item) && getGoal(item)"
                    class="mb-8 levelOne"
                    :item="getGoal(item)"
                    :parent="null"
                    :handleCreate="handleCreate"
                    :handleDelete="handleDelete"
                    :handleUpdate="handleUpdate"
                    :goalId="item.id"
                />
            </div>
        </div>
        <PanelLoader v-if="!goals.length && loader"/>
    </BaseLayout>
</template>

<script setup>
    import { set, onMounted, computed, watch } from 'vue';
    import BaseLayout from "@/views/layouts/dashboard/BaseLayout.vue"
    import Header from "@/components/dashboard/goal/Header.vue"
    import Panel from "@/components/dashboard/goal/Panel.vue";
    import PanelLoader from "@/components/dashboard/goal/PanelLoader.vue";
    import router from '@/routes/routes.js';
    import { useGoal } from './useGoal';
    import useCrud from './useCrud';
    import {loader} from './useLoader'
    import LeftSidebar from '@/views/screens/dashboard/leftSidebar/Index.vue'
    import { isEmpty } from 'lodash'

    const route = router.currentRoute;
    const {goals, showActiveOnly, showConfiguration, loadGoals, handleGoalCreate, handleGoalDelete, updateActivity} = useGoal()
    const {createItem, updateItem, deleteItem} = useCrud()

    const handleCreate = (item, parent) => {
        if(item.i_am == 'goal') {
            handleGoalCreate(route.params.workspaceId, item.id)
            return
        }
        else if(item.i_am == 'activity') {
            createItem(item, parent, item.i_am, {
                key_activity_id: parent.id,
                title: "Activity title",
                description: "Activity description",
                start_date: "2023-02-1",
                end_date: "2023-02-28",
                type: 2,
                baseline: 2,
                goal: 10,
                target: 8.5,
                weight: 30,
                actual_value: 4,
                system_data: [],
                priority: 1
            })
            return
        }
        createItem(item, parent, item.i_am)
    } 
    const handleDelete = (item, parent) => {
        if (!confirm('Do you want to delete?')) return
        if(item.i_am == 'goal') {
            handleGoalDelete(item.id)
            return
        }
        deleteItem(item, parent)
    }
    const handleUpdate = (item) => {
        if(item.i_am == 'activity') {
            updateActivity(item)
            return
        }
        updateItem(item)
    }

    const getGoal = (item) => {
        changeStatus(goals.value, showActiveOnly.value)
        if(!showActiveOnly.value){
            return item
        }

        if(item.status){
            return item
        }
        return false
    }

    onMounted(() => {
        goals.value = []
        loadGoals(route.params.workspaceId)
    })

    watch(showActiveOnly, () => {
        changeStatus(goals.value, showActiveOnly.value)
    })

    const changeStatus = (data, status) => {
        if(Array.isArray(data)){
            data.forEach(item => {
                changeStatus(item, status)
                if(!isEmpty(item.children)){
                    changeStatus(item.children, status)
                }
            })
            return
        }
        
        set(data, 'showActiveOnly', status)
    }
</script>

<style scoped>
    .h-960px{
        height: 960px;
    }
</style>