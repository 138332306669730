<template>
    <div class="flex justify-between gap-4 w-full mr-20">
        <div class="flex gap-3 items-center">
            <DownloadIcon />
            <h1 class="fs-14 fw-600">
                Goal Management Instrat360
            </h1>
        </div>
        <div class="fs-14 fw-500 flex gap-12">
            <div class="flex items-center">
                Show Active Only
                <SwitchComponent class="transform scale-50 origin-center-right mr-auto" v-model="showActiveOnly"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"
import DownloadIcon from "@/components/dashboard/goal/icons/DownloadIcon.vue"
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import { useGoal } from '@/views/screens/dashboard/useGoal.js'

const {showActiveOnly} = useGoal()
</script>