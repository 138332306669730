import axios from 'axios'
import {setLoader} from './useLoader'

const parentIdMap = {
    'goal': 'workspace_id',
    'objective': 'goal_id',
    'key-result': 'objective_id',
    'key-activity': 'key_responsibility_id',
    'activity': 'key_responsibility_id',
}
const titleMap = {
    'goal': 'Goal Title',
    'objective': 'Objective Title',
    'key-result': 'Result Title',
    'key-activity': 'Key Activity Title',
    'activity': 'Activity Title',
}

const apiCreateURLMap = {
    'goal': '/workspaces/goals/create',
    'objective': '/workspaces/goals/objectives/create',
    'key-result': '/workspaces/goals/objectives/key-results/create',
    'key-activity': '/workspaces/goals/objectives/key-results/key-activities/create',
    'activity': '/workspaces/goals/objectives/key-results/key-activities/activities/create',
}

const apiUpdateURLMap = {
    'goal': '/workspaces/goals',///1/update
    'objective': '/workspaces/goals/objectives',///1/update
    'key-result': '/workspaces/goals/objectives/key-results',///1/update
    'key-activity': '/workspaces/goals/objectives/key-results/key-activities',///1/update
    'activity': '/workspaces/goals/objectives/key-results/key-activities/activities',///1/update
}

const apiDeleteURLMap = {
    'goal': '/workspaces/goals',
    'objective': '/workspaces/goals/objectives',
    'key-result': '/workspaces/goals/objectives/key-results',
    'key-activity': '/workspaces/goals/objectives/key-results/key-activities',
    'activity': '/workspaces/goals/objectives/key-results/key-activities/activities'
}

export default function useResult() {
    const createItem = async(clickedItem, parent, iAm, payload=null) => {
        setLoader(true)
        let createData = {
            [`${parentIdMap[iAm]}`]: parent.id,
            title: `${titleMap[iAm]}`,
            description: `${iAm} description`
        }
        if(payload) createData = payload
        const { data } = await _create(createData, iAm)
        setLoader(false)
        if(data && data.status && clickedItem && clickedItem.id && parent) {
            const itemIndex = parent.children.findIndex(({id}) => id == clickedItem.id)
            parent.children.splice(itemIndex+1, 0, data.data)
            return
        }
        if(data && data.status && parent)  parent.children.push(data.data)
    }
    
    const deleteItem = async(item, parent) => {
        setLoader(true)
        const { data } = await _delete(item.id, item.i_am)
        setLoader(false)
        if(data && data.status) {
            const itemIndex = parent.children.findIndex(({id}) => id == item.id)
            parent.children.splice(itemIndex, 1)
        }
    }

    const updateItem = async(item) => {
        let payload = {
            [`${parentIdMap[item.i_am]}`]: item[`${parentIdMap[item.i_am]}`],
            title: item.title,
            description: item.description,
            status: item.status
        }
        setLoader(true)
        const { data } = await _update(payload, item.id, item.i_am)
        setLoader(false)
    }
    const _create = async (data, iAm) => await axios.post(apiCreateURLMap[iAm], data)
    const _update = async (payload, itemId, iAm) => await axios.post(`${apiUpdateURLMap[iAm]}/${itemId}/update`, payload)
    const _delete = async (itemId, iAm) => await axios.delete(`${apiDeleteURLMap[iAm]}/${itemId}`)

    return {
        createItem,
        updateItem,
        deleteItem
    }
}