<template>
    <div>
        <div class="px-10 py-8 overflow-y-auto" style="height: 930px;">
            <Item 
                v-for="goal in goals"
                :key="goal.id"
                :item="goal"
                :goalId="goal.id"
                :handleExpandedId="handleExpandedId"
            />
        </div>
        <Nav v-model="showNavigation" @close="showNavigation=false"/>
    </div>
</template>

<script setup>
    import { useGoal } from '@/views/screens/dashboard/useGoal.js'
    import Item from '@/views/screens/dashboard/leftSidebar/Item.vue'
    import Nav from '@/components/navigation/Nav.vue'
    import useNavigation from '@/components/navigation/useNavigation.js'
    import { onMounted } from 'vue'
    
    const { goals, handleExpandedId, expandedIds } = useGoal()
    const { showNavigation } = useNavigation()

    // onMounted(() => console.log(goals.value))
</script>