<template>
    <div class="pl-5 pr-6 flex-shrink-0">
        <table class="fw-500 fs-12 text-primary-one">
            <tr>
                <td class="py-1">Time:</td>
                <td class="pl-4 py-1">
                    <div class="truncate w-20">
                        {{ completedDate }} / {{ totalDays }} Days
                    </div>
                </td>
            </tr>
            <tr>
                <td class="py-1">WPTT:</td>
                <td class="pl-4 py-1 fw-800">{{ item.weight_progress_towards_target }}%</td>
            </tr>
            <tr>
                <td class="py-1">Due Date:</td>
                <td class="pl-4 py-1">{{ item.end_date }}</td>
            </tr>
        </table>
    </div>
</template>

<script setup>
import { dateDefer } from '@/plugins/lodash.js';
import { computed } from 'vue';
import { format, addDays } from 'date-fns'
const props = defineProps({
    item: Object
});
const totalDays = computed(() => {
    return dateDefer(props.item?.start_date, props.item?.end_date) || 0
});

const completedDate = computed(() => {
    let today = format(addDays(new Date(), 0), 'yyyy-MM-dd');
    return dateDefer(props.item?.start_date, today) || 0
});

</script>