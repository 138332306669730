<template>
    <div class="h-full grid grid-cols-3 flex-shrink-0" style="width: 771px;">
        <div class="flex items-center gap-2 border-r" style="width: 258px;">
            <h1 class="fs-28 fw-700 pl-5" :style="{color: item.progress_time_color}">
                {{ Math.floor(item.weight_progress_towards_target) }}%
            </h1>
            <p class="fs-12 fw-500 pr-2">{{ item.progress_with_time_text }}</p>
        </div>
        <div class="flex items-center gap-2 border-r" style="width: 258px;">
            <h1 class="fs-28 fw-700 pl-5" :style="{color: item.progress_time_color}">
                {{ Math.floor(item.confidence_weight_progress_towards_target) }}%
            </h1>
            <p class="fs-12 fw-500 pr-2">Actual confidence towards target based on associated activities.</p>
        </div>
        <div class="flex items-center gap-2 border-r" style="width: 255px;">
            <h1 
                class="fs-28 fw-700 pl-5" 
                style="color: #3D3D3D;" 
            >
                {{ getValueWithUnit(Math.round(item.total_outcome)) }}
            </h1>
            <p class="fs-12 fw-500 pr-2">Expected outcome generated based on value drivers and Progress.</p>
        </div>
    </div>
</template>

<script setup>
    defineProps({
        item: Object
    })

    const getValueWithUnit = (value) => {
        if(!value) return 0
        if (value >= 1000000) {
            return Math.floor((value / 1000000)) + "M"
        } else if (value >= 1000) {
            return Math.floor((value / 1000)) + "K"
        } else {
            return value
        }
    }
</script>

<style lang="scss" scoped>

</style>